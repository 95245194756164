import mutations from './mutations'
import actions from './actions'

const state = {
  shallContentShowOverlay: false,

  // app_name: process.env.VUE_APP_NAME,
  app_name: 'Wamasol Tech',
  emailsCodes: [],
  emailsReserve: [],
  ctasBancarias: [],
  user: {
    permisos: [],
  },
  viewPermisos: null,
  isLoadingPagePermisos: true,
  sourcePermisos: [],
  sourceTableros: [],
  updateBooking: false,
  showBooking: true,
  showProduct: true,
  showContrate: true,
  showAdministration: true,
  showAPIs: true,
  showConfiguration: true,
  showNomencladores: true,
  showNomencladoresFlights: true,
  showNomencladoresCars: false,
  showNomencladoresHotels: false,

  userProfile: {},
  isLoadingPage: true,
  perfilesAll: [],
  perfilesAuth: [],
  permisosItem: [],
  cargandoMenu: true,

  itemsPerPage: 10,

  loadEscalas: false,
  escalas: [],

  loadFrecuencias: false,
  frecuencias: [],

  loadClasesVuelos: false,
  loadTarifasVuelos: false,
  clasesVuelos: [],
  umPesoBodega: '',
  umDimensionBodega: '',

  // USERS
  searchUsers: {
    name: null,
    company: 0,
    rol: 0,
    active: 2,
  },
  userUpdate: {
    id: null,
    name: null,
    apellidos: null,
    email: null,
    role_id: null,
    active: 1,
  },
  userOnlyShow: false,
  userUpdateAvatar: null,
  userItemsPerPage: 10,
  totalUsersFilters: 0,
  totalUsersAll: 0,

  // MARKUPS
  temporadasFlightMarkups: [],
  markupsFlightMarkups: [],

  // GEOTAG
  geotag: {
    longitud: null,
    latitud: null,
  },

  textEnriquecido: null,

  // FLIGHTS
  airport: {
    iata: null,
    name: null,
    country: null,
    state: null,
    city: null,
    direccion: null,
  },
  oficinaRenta: {
    name: null,
    ptos_recogida: null,
    country: null,
    country_code: null,
    state: null,
    city: null,
    direccion: null,
  },
  onlyShow: false,

  // CARS
  telefonos: [],
  horarios: [],
  galeria: [],
  oficceRent: [],
  paiseCode: {
    name: null,
    countrySlug: null,
    code: null,
  },
  driverAgeCumple: false,

  // MARKUPS
  markupsList: [],

  // CONTRATE
  suplementosContrateCar: [],
  suplementoCar: {
    from: null,
    to: null,
    price: 0,
    suplemento_id: null,
    obligatorio: false,
  },
  categoryContrateCar: [],
  marcasModeloContrateCar: [],
  temporadasContrateCar: [],
  pricesTemporadasContrateCar: [],
  cargandoCuposTemporadasContrateCar: false,

  // FLIGHT
  suplementosContrateFlight: [],
  suplementoFlight: {
    from: null,
    to: null,
    price: 0,
    suplemento_id: null,
    obligatorio: false,
    online: false,
  },

  // HOTELS
  loadImg: false,
  galeriaHotels: [],
  videos: [],
  videos_url: [],
  addressHotel: {
    country: null,
    state: null,
    city: null,
    direccion: null,
  },
  contactsHotel: [],
  onlyAdult: false,
  allInclude: false,

  // CONTRATE
  observationsHotel: [],
  idsHotelsAsociados: [],
  temporadasContrateHotel: [],
  hotelsAsociadosTemporadas: [],
  idsHotelsAsociadosTemporadas: [],
  cantPaxsContrate: 3,
  pricesHotelContrate: [],
  seasonContrateHotel: {
    price_estancia_dbl: 'pppn',
    is_tarifa_price_estancia_dbl: true,
  },
  seasonsContrateHotel: [],
  roomsSeasonContrateHotel: [],
  maxOcupationRoomsSeasonContrateHotel: [],
  cuposRoomsSeasonContrateHotel: [],
  releaseRoomsSeasonContrateHotel: [],
  estaciaMinRoomsSeasonContrateHotel: [],
  pricesDBLRoomsSeasonContrateHotel: [],
  suplementsReduccTarifasSeasonContrateHotel: [],
  suplementsAlimenticiosSeasonContrateHotel: [],
  suplementsOtrosSeasonContrateHotel: [],
  politicasChildSeasonContrateHotel: [],
  suplementsSeasonContrateHotel: [],
  reduccionsSeasonContrateHotel: [],
  rangeAgeContrateHotel: {
    adults_min_years: 13,
    childs_max_years: 12.99,
    infant_max_years: 1.99,
  },
  ofertasSeasonContrateHotel: [],
  ofertasSpecialSeasonContrateHotel: [],

  // COTIZADOR
  cotizadorFlight: {
    cant_adults: 1,
    cant_ninnos: 0,
    cant_infant: 0,
    aerolinea: 0,
    aerolinea_name: 'Todas',
    onlyIda: false,
    destinosMultiples: false,
  },
  destinosCotizadorFlight: [
    {
      origen: null,
      destino: null,
      from: null,
      to: null,
    },
  ],
  searchingFlightCotizador: false,
  pageSearchingFlightCotizador: 1,
  resultCotizadorIdaFlight: [],
  aerolineCotizadorIdaFlight: [],
  searchCotizadorIdaFlight: false,
  searchCotizadorFlights: false,
  aerolineCotizadorRegresoFlight: [],
  aerolineCotizadorDestinosFlight: [],
  tarifaEconCotizadorIdaFlight: {},
  resultCotizadorBackFlight: [],
  filtrosCotizadorFlight: {
    aerolinea: 0,
    escala: 0,
    duracionEscala: 20,
    duracionViaje: 20,
    frecuencias: ['0', '1', '2', '3', '4', '5', '6'],
    airport: {},
    clase: 0,
    equipaje: 0,
    byMejorOpcion: true,
    byMasEconomico: false,
    byMasRapido: false,
  },
  cotizadorFlightSelect: null,
  cotizadorFlightSelectMarkups: {
    markup: 0,
    valueMarkup: null,
    descuento: 0,
    valueDescuento: null,
  },
  cotizadorPaxFlightSelect: [],
  cotizadorFlightFavorite: [],
  idFlightFavorite: [],
  scheduleResult: [],
  pageResult: 1,
  itemsPerPageResult: 10,
  searchResult: null,
  posCotizador: 0,

  // CARS
  cotizadorCars: {
    recogida: null,
    entrega: null,
    entregaDiffRecogida: false,
    dateRecogida: null,
    dateEntrega: null,
    transmision_id: null,
    rentadora_id: null,
  },
  declineTerms: true,
  searchingCar: false,
  cotizadorCarsResult: [],
  cotizadorCarsResultSelect: null,
  cotizadorCarsResultSelectEdit: null,
  cotizadorCarsResultSelectEditWith: null,
  cotizadorPaxCarSelect: {
    nameConductorPrincipal: null,
    apellidosConductorPrincipal: null,
    nameSecoundConductorPrincipal: null,
    birthdayConductorPrincipal: null,
    nationalityConductorPrincipal: null,
    sexConductorPrincipal: null,
    noDocumentConductorPrincipal: null,
    noDocumentConductorPrincipalScanner: null,
    conductorAditional: false,
    nameConductorAditional: null,
    apellidosConductorAditional: null,
    nameSecoundConductorAditional: null,
    birthdayConductorAditional: null,
    nationalityConductorAditional: null,
    sexConductorAditional: null,
    noDocumentConductorAditional: null,
    noDocumentConductorAditionalScanner: null,
  },
  sameParamters: true,
  noDocumentConductorPrincipalScanner: null,
  noDocumentConductorAditionalScanner: null,
  cotizadorCarsSelectMarkups: {
    markup: 0,
    valueMarkup: null,
    descuento: 0,
    valueDescuento: null,
  },
  avalibleFiltrosCotizadorCar: false,
  filtrosCotizadorCar: {
    marca: null,
    modelo: null,
    transmision: null,
    tipo_auto: null,

    // byMejorOpcion: true,
    // byMasEconomico: false,
    // byMasRapido: false,
  },

  // HOTELS
  cotizadorHotels: {
    destino: null,
    dateIn: null,
    dateOut: null,
    proveedor_id: null,
    categoria_id: null,
    tipo_destino_id: null,
    ocupation: [
      {
        adults: 2,
        childs: 0,
        edades: [],
        room_id: null,
        paxs: [],
        showAllPaxs: false,
        priceTotal: null,
        priceWithMarkups: null,
        priceWithDescuento: null,
      },
    ],
  },
  cotizadorHotelsRomms: [],
  showOcupationRoomHotel: false,
  searchingHotel: false,
  cotizadorHotelsResult: [],
  cotizadorHotelsResultSelect: null,
  cotizadorHotelsSelectMarkups: {
    markup: 0,
    valueMarkup: null,
    descuento: 0,
    valueDescuento: null,
  },

  // AFILIADOS
  contactsAfiliados: [],

  // RESERVAS
  comentsGestor: [],
  comentsClient: [],

  // STOP SALE
  watchEvent: false,
  stopSales: {
    id: null,
    products: [],
    proveedor_id: [],
    contrate_id: [],

    // CARS
    category_id: [],
    marca_modelo_id: [],

    // HOTELS
    hotel_id: [],
    room_id: [],

    // FLIGHTS
    aeroline_id: [],
    flight_id: [],

    temporada_id: [],
  },
  stopSalesList: [],
  datesStopSale: [],

  // OFERTAS
  ofertEBBList: [],
  ofertEBBDates: {},
  ofertEBBDatesOrigin: {},
  indexHotelEBB: -1,
  showHotelEBB: false,
  indexRoomEBB: -1,
  showRoomEBB: false,

  // CAR-SHOP
  carItems: [],
  clientCarShop: {
    name: null,
    apellidos: null,
    email: null,
    subject: null,
    message: null,
    telefono: null,
    is_client_corporate: false,
    id_client_corporate: null,
    id_cta_bancaria: null,
    emails: [],
  },
  indexCarItem: -1,
  showCarItem: false,
  loadingBtn: false,

  // CTAS
  checkAll: false,
  itemsCheck: [],
  checkAllCobros: false,
  itemsCheckCobros: [],
  checkAllPaysEmit: false,
  itemsCheckPaysEmit: [],
  checkAllPaysRec: false,
  itemsCheckPaysRec: [],

  // SEASTING
  seasting: {
    headersLeft: [],
    seatsLeft: [],
    headersRight: [],
    seatsRight: [],
    count: {
      columnsLeft: 3,
      columnsRight: 3,
      rows: 15,
    },
  },

  // EXPORT
  filtrosExport: {},

  // TARIFARIOS
  itemsTarifarios: [],
  searchCars: false,
  searchHotels: false,
  searchFlights: false,

  // PROPIETARIO
  loadingContent: false,
  dataBancaria: {
    tipo_fiscal: null,
    cif_nif_facturation: null,
    info_fiscal: {
      address: [
        {
          address: null,
        },
      ],
      code_postal: null,
      localidad: null,
      country: null,
    },
  },

  // CHATBOT
  optionsChatBotMessages: [],
  chatActiveAdmin: null,
}

export default {
  state,
  mutations,
  actions,
}
