/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line camelcase, import/no-unresolved
import messages_es from '@/plugins/i18n/locales/es'
// eslint-disable-next-line camelcase, import/no-unresolved
import messages_en from '@/plugins/i18n/locales/en'
// eslint-disable-next-line import/no-unresolved, camelcase
import messages_pt from '@/plugins/i18n/locales/pt'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// DETECTAR IDIOMA DEL NAVEGADOR
const language = navigator.language || navigator.userLanguage

export const i18n = new VueI18n({
  locale: language
    ? language.includes('es')
      ? 'es'
      : language.includes('en')
        ? 'en'
        : language.includes('pt')
          ? 'pt'
          : 'es'
    : 'es', // set locale
  fallbackLocale: 'en',
  messages: {
    es: messages_es,
    en: messages_en,
    pt: messages_pt,
  }, // set locale messages
})

const loadedLanguages = ['es'] // nuestro idioma predeterminado que está precargado

function setI18nLanguage(lang) {
  i18n.locale = lang

  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  /* eslint-disable prefer-template */
  return import(/* webpackChunkName: "lang-[request]" */ '@/plugins/i18n/locales/' + lang + '.js').then(msgs => {
    i18n.setLocaleMessage(lang, msgs.default)
    loadedLanguages.push(lang)

    return setI18nLanguage(lang)
  })
  /* eslint-enable */
}
